import React from 'react';
import { socket } from '../../socket';
import { useParams } from 'react-router-dom';

const SSHTab = ({ server }) => {
	let { id } = useParams();

	const launchSSHTunnel = (ev) => {
		ev.preventDefault()
		socket.emit('ssh:start', {
			id,
			port: ev.target[0].value,
			host: ev.target[1].value,
		})
	}

	return (
		<>
				<form onSubmit={launchSSHTunnel}>
		<h3>Connexion SSH par tunel</h3>
		<div>
			<label>Port</label>
			<input type='number'/>
		</div>
		<div>
			<label>host distant (avec username)</label>
			<input/>
		</div>
		<button type='submit'>
			Déclencher
		</button>
	</form>
	<>
		<h2>{server?.tunnel?.activate === true ? 'Activé' : 'Désactivé'}</h2>
		{server?.tunnel?.activate === true && <h2>On {server?.tunnel.host}, exec : ssh -p {server?.tunnel?.port} quizroom@localhost</h2>}
		{server?.tunnel?.activate === true && <button onClick={() => socket.emit('ssh:stop')}>STOP</button>}
		{server?.tunnel?.activate === false && server.tunnel.error && <h3 style={{ color: 'red' }}>Error: {server.tunnel.error}</h3>}
	</>
		</>
	);
};

export default SSHTab;