import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'
import ServerList from './Pages/ServerList';
import ServerDetails from './Pages/ServerDetails/ServerDetails.jsx'
import { socket } from './socket';
import { useAuth0 } from '@auth0/auth0-react';

export const ContextData = React.createContext();

function App() {
	const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

	const [servers, setServers] = useState([]);

	useEffect(() => {
		socket.on('servers', (servers) => setServers(servers));
	}, []);

	useEffect(() => {
		(async function login() {
		  if (!isLoading && !isAuthenticated) {
			await loginWithRedirect();
		  }
		})();
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [isLoading]);

	return isAuthenticated && (
		<ContextData.Provider value={{
			servers
		}}>
		<BrowserRouter>
		<Routes>
		  <Route exact path="/" Component={ServerList} />
		  <Route path="/server/:id" Component={ServerDetails} />
		</Routes>
	  </BrowserRouter>
	  </ContextData.Provider>
	);
  }
export default App;
