import React from "react";
import { useNavigate } from "react-router-dom";
import styles from './ServerCard.module.css'
import ServerInformations from "./ServerInformations";
import classNames from "classnames";
const ServerCard = ({ server }) => {
	const navigate = useNavigate();

  return (
	<div className={classNames(styles.server, {[styles['server-off']]: !server?.status || server?.status === 'off'})}>
		<h2 className={styles.label}>{server.label}</h2>
		<ServerInformations server={server}/>
		<button className={classNames(styles.button, {[styles['button-disabled']]: !server?.status || server?.status === 'off'})} onClick={() => navigate("/server/" + server.id)}>
			Ouvrir
		</button>
	</div>
  );
};

export default ServerCard;
