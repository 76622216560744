import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContextData } from '../../App';
import './ServerDetails.css'
import ServerInformations from '../../Components/ServerInformations';
import SSHTab from './SSHTab';
import UpdateTab from './UpdateTab';

const ServerDetails = () => {
	const [server, setServer] = useState(null)

	let { id } = useParams();
	const { servers } = useContext(ContextData)


	useEffect(() => {
		setServer(servers.find((server) => server.id === id))
	}, [id, servers]);


	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabClick = (index) => {
	  setSelectedTab(index);
	};

	if (!server) {
		<div>Loading ...</div>
	}

	return (
		<div className='server-details'>
			<h1>{server?.label}</h1>
			<div className="tab-header">
				<div
					className={`tab-item ${selectedTab === 0 ? "active" : ""}`}
					onClick={() => handleTabClick(0)}
				>
					Informations
				</div>
				<div
					className={`tab-item ${selectedTab === 1 ? "active" : ""}`}
					onClick={() => handleTabClick(1)}
				>
					SSH
				</div>
				<div
					className={`tab-item ${selectedTab === 2 ? "active" : ""}`}
					onClick={() => handleTabClick(2)}
				>
					Mise à jour
				</div>
			</div>
			<div className="tab-content">
				{selectedTab === 0 && <ServerInformations server={server} />}
				{selectedTab === 1 && <SSHTab server={server} /> }
				{selectedTab === 2 && <UpdateTab server={server} /> }
			</div>
		</div>
	)
}

export default ServerDetails;


//ssh -NR 12345:localhost:22 quizroom@room1.quizroom.fr