import React, { useState, useContext } from 'react';
import ServerCard from '../Components/ServerCard';
import { ContextData } from '../App';
import styles from './ServiceList.module.css'

function ServerList() {
  const [searchTerm, setSearchTerm] = useState("");
	const { servers } = useContext(ContextData)


	const handleSearch = (event) => {
		setSearchTerm(event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
	  };

	  const getFilteredServers = () => {
		return servers.filter((server) =>
		  server.id.toLowerCase().includes(searchTerm?.toLowerCase())
		);
	  };


	  const filteredServers = getFilteredServers();

  return (
	<div className={styles.wrapper}>
	<header className={styles.header} >
		<h1>État des serveurs</h1>
	</header>
	<div className={styles['search-bar-wrapper']}>
		<input
			type="text"
			placeholder="Search servers..."
			value={searchTerm}
			onChange={handleSearch}
			className={styles['search-bar']}
		/>
	</div>
	<div className={styles.container}>
	{filteredServers.map((server) => (
	<ServerCard key={server.id} server={server} />
	))}
	</div>
	</div>
  );
}

export default ServerList;
