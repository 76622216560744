import React from "react";
import styles from "./ServerInformations.module.css";

const ServerInformations = ({ server }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles['field-wrapper']}  >
                <span className={styles['left-field']}>
                    État :
                </span>
				<span className={styles['right-field']}>
					{server?.status === "on" ? "allumé" : "éteint"}
                </span>
            </div>
            <div className={styles['field-wrapper']}  >
                <span className={styles['left-field']}>
                    Dernier redémarrage :
                </span>
				<span className={styles['right-field']}>
					{server?.lastReboot
                        ? new Date(server?.lastReboot).toLocaleString()
                        : null}
                </span>
            </div>
				<>
				{Object.keys(server?.gits || {}).map((git, i) => (
					<div key={git} className={styles['field-wrapper']}>
						<span className={styles['left-field']}>
							{git}
						</span>
						<span className={styles['right-field']}>
							{server?.gits?.[git] === "up-to-date" ? "À jour" : "Pas à jour"}
						</span>
					</div>
				))}
				</>
			<div className={styles['field-wrapper']}  >
                <span className={styles['left-field']}>
                    Dernière réponse de ping :
                </span>
				<span className={styles['right-field']}>
                    {server?.lastPingResponse
                        ? new Date(server?.lastPingResponse).toLocaleString()
                        : null}
                </span>
            </div>
        </div>
    );
};

export default ServerInformations;