import React, { PropTypes, useCallback, useEffect, useState } from 'react';
import { socket } from '../../socket';

const UpdateTab = ({ server }) => {
    const [logs, setLogs] = useState()

    const callUpdate = useCallback(() => {
        socket.emit('repos:update', {
			id: server.id,
		}, (val) => console.log('val', val))
    }, [server.id])

    useEffect(() => {
        socket.on('logs', (newLogs) => {
            setLogs(newLogs)
        })
    }, [])
    return (
        <div>
            <button onClick={callUpdate}>Mettre à jour</button>
            <div>{logs}</div>
        </div>
    );
};

UpdateTab.propTypes = {
    
};

export default UpdateTab;